export default class EnumRequestScheme {
  constructor() {
      this.items = [
          {
              "id": 1,
              "text": "https",
              "value": "1",
              "variant": "light-info"
          },
          {
              "id": 2,
              "text": "http",
              "value": "2",
              "variant": "light-warning"
          },
      ];
  }
}