<template>
  <div>
    <b-card
      class="pt-0 mt-1"
    >
      <b-card-body class="pt-25" v-if="service">
        <div class="d-flex">
          <b-col md=3 class="profile-image p-0 m-0">
            <b-avatar
              size="90px"
              :src="`https://robohash.org/${service.id}`"
            />
          </b-col>
          <b-col>
            <h3>{{ service.name }}</h3>
            <h6 class="flex-wrap text-muted">{{ `${getEnumRequestType(service.enum_request_scheme_id).text}://${service.host}`}}</h6>
            <b-badge class="profile-badge" variant="light-primary"> Pro Level </b-badge>
          </b-col>
        </div>
        <hr class="mb-1" />
        <!-- follower projects rank -->
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h6 class="text-muted font-weight-bolder small">{{ $t('common.terms.updated_at') }}</h6>
            <h6 class="mb-0">{{ updatedAt }}</h6>
          </div>
          <div>
            <h6 class="text-muted font-weight-bolder small">{{ $t('common.terms.owned_by') }}</h6>
            <!-- <h6 class="mb-0">{{ service.organization.name }}</h6> -->
          </div>
        </div>
        <div>
          <b-button block variant="outline-warning" class="mt-2 text-uppercase" @click="webServiceInfo">{{ $t('common.terms.plus_details') }}</b-button>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      :id="getID('details-modal')"
      ok-only
      :ok-title="$t('common.terms.close')"
      centered
      size="xl"
      ok-variant="outline-secondary"
      :title="$t('common.terms.service_detail')"

      v-model="detailsShown"
    >
      <CardDetails :service="service" />
    </b-modal>
  </div>
</template>

<script>
import { BCard, BAvatar, BBadge, BCardBody, BButton, BCol } from "bootstrap-vue";
import moment from "moment";
import CardDetails from "./CardDetails.vue";
import { v4 as uuidv4 } from "uuid";
import Scheme from '@/custom/class/Integrator/Scheme.js'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BCardBody,
    BButton,
    CardDetails,
    BCol,
  },
  props: {
    service: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      uuidMap: {},
      detailsShown: false,
    }
  },
  computed: {
    updatedAt() {
      return moment(this.service.updated_at).format("LLL");
    },
  },
  methods: {
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    getEnumRequestType(type) {
      return new Scheme(type)
    },
    webServiceInfo() {
      this.$emit('openService', this.service)
    }
  }
};
</script>