<template>
  <div>
    <b-row class="justify-content-center align-items-center text-center">
      <b-col cols="12" class="mb-1">
        <div class="text-center">
          <div class="profile-image p-0">
            <b-avatar
              size="120px"
              :src="`https://robohash.org/${service.id}`"
            />
          </div>
        </div>
      </b-col>
      <b-col sm="6" md="3" class="mb-1">
        <h6 class="text-muted font-weight-bolder small">
          {{ $t("common.terms.name") }}
        </h6>
        <h6 class="mb-0">{{ service.name }}</h6>
      </b-col>
      <b-col sm="6" md="3" class="mb-1">
        <h6 class="text-muted font-weight-bolder small">
          {{ $t("common.terms.scheme") }}
        </h6>
        <h6 class="mb-0">{{ service.scheme }}</h6>
      </b-col>
      <b-col sm="6" md="3" class="mb-1">
        <h6 class="text-muted font-weight-bolder small">
          {{ $t("common.terms.host") }}
        </h6>
        <h6 class="mb-0">{{ service.host }}</h6>
      </b-col>
      <b-col sm="6" md="3" class="mb-1">
        <h6 class="text-muted font-weight-bolder small">
          {{ $t("common.terms.created_at") }}
        </h6>
        <h6 class="mb-0">{{ createdAt }}</h6>
      </b-col>
      <b-col sm="6" md="3" class="mb-1">
        <h6 class="text-muted font-weight-bolder small">
          {{ $t("common.terms.updated_at") }}
        </h6>
        <h6 class="mb-0 text-primary">{{ updatedAt }}</h6>
      </b-col>
    </b-row>
    <hr />
    <b-row class="d-flex justify-content-between">
      <b-col md="3" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("common.terms.per_page")
          }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('common.terms.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('common.terms.type_to_search')"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t("common.terms.clear") }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-table
          v-if="items"
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
          :empty-filtered-text="$t('common.terms.no_records_matching')"
          :empty-text="$t('common.terms.no_records_show')"
        >
          <template #cell(enum_release_tag)="data">
            <b-badge
              :variant="new ReleaseTags(data.value.id).variant"
              class="mr-2"
            >
              {{ $t(data.value.label) }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <b-button
              class="mr-1"
              size="sm"
              variant="success"
              @click="install(data.item)"
              ><feather-icon icon="PlusIcon" />
              {{ $t("common.terms.install") }}</b-button
            >
            <b-button
              class="mr-1"
              size="sm"
              variant="info"
              @click="view(data.item)"
              ><feather-icon icon="EyeIcon" />
              {{ $t("common.terms.details") }}</b-button
            >
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      :id="getID('release-viewer')"
      ok-only
      :ok-title="$t('transmission.log.table.close')"
      centered
      size="xl"
      :title="$t('transmission.integrator.modal.script')"
    >
      <json-viewer
        v-if="viewingRelease"
        :value="getJsonData(viewingRelease)"
        :expand-depth="5"
        copyable
        boxed
        :theme="`jv-${$store.state.appConfig.layout.skin}`"
        sort
      ></json-viewer>
    </b-modal>

    <b-modal
      :id="getID('import-script')"
      centered
      size="md"
      :title="$t('transmission.integrator.modal.script')"
      @ok="installTransmissionScript"
    >
      <p class="px-1 pt-1">
        {{ $t('transmission.integrator.modal.import.message') }}
      </p>
      <b-alert show variant="danger">
        <p class="px-2 py-1">
          {{ $t('common.terms.caution') + '!' }} <br/> {{ $t('transmission.integrator.modal.import.caution_message') }}
        </p>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BCardBody,
  BButton,
  BTable,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormSelect,
  BAlert,
} from "bootstrap-vue";
import moment from "moment";
import CardDetails from "./CardDetails.vue";
import { v4 as uuidv4 } from "uuid";
import { makeToast } from "@/layouts/components/Popups.js";
import ReleaseTags from "@/custom/class/Release/Tags";
import JsonViewer from "vue-json-viewer";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
    BCardBody,
    BButton,
    CardDetails,
    BTable,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    JsonViewer,
    BAlert,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ReleaseTags,
      transmissionScript: null,
      viewingRelease: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      uuidMap: {},
      fields: [
        {
          key: "version",
          label: this.$t("integrator.table.release.fields.version"),
          sortable: true,
        },
        {
          key: "enum_release_tag",
          label: this.$t("integrator.table.release.fields.tag"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("integrator.table.release.fields.created_at"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("integrator.table.release.fields.actions"),
          thStyle: "width: 350px;",
        },
      ],
    };
  },
  computed: {
    createdAt() {
      return moment(this.service.created_at).format("LLL");
    },
    updatedAt() {
      return moment(this.service.updated_at).format("LLL");
    },
    items() {
      return this.$store.getters.getWebServiceReleaseByID(this.service.id);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.fetchReleases();
    },
    fetchReleases() {
      this.$store
        .dispatch("getAllWebServiceRelease", {
          web_service_id: this.service.id,
        })
        .then((response) => {
          this.totalRows = response.length;
        })
        .catch(() => {
          makeToast({
            title: this.$t("integrator.toast.get_releases.error.title"),
            text: this.$t("integrator.toast.get_releases.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    view(item) {
      this.viewingRelease = item.script;
      this.$bvModal.show(this.getID("release-viewer"));
    },
    getJsonData(json) {
      try {
        return JSON.parse(json);
      } catch (e) {
        return { error: this.$t("common.terms.invalid_json"), received: json };
      }
    },
    install(item) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("integrator.modal.confirm_install.message", {
            request: item.name,
          }),
          {
            title: this.$t("integrator.modal.confirm_install.title"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("common.terms.yes"),
            cancelTitle: this.$t("common.terms.no"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("installWebService", {
                id: item.id,
                organization: {
                  id: item.web_service.organization.id,
                },
                ...this.$route.params
              })
              .then(() => {
                if (item.transmission_script) {
                  this.$bvModal.show(this.getID("import-script"));
                  this.transmissionScript = item.transmission_script
                }
                makeToast({
                  title: this.$t(
                    "integrator.toast.confirm_install.success.title"
                  ),
                  text: this.$t(
                    "integrator.toast.confirm_install.success.message"
                  ),
                  variant: "success",
                  icon: "CheckIcon",
                });
              })
              .catch((error) => {
                console.log(error);
                makeToast({
                  title: this.$t(
                    "integrator.toast.confirm_install.error.title"
                  ),
                  text: this.$t(
                    "integrator.toast.confirm_install.error.message"
                  ),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          }
        });
    },
    installTransmissionScript() {
      this.$store.dispatch('importScript', { script: this.transmissionScript })
        .then(() => {
          makeToast({
            title: this.$t(
              "integrator.toast.confirm_install.success.title"
            ),
            text: this.$t(
              "integrator.toast.confirm_install.success.message"
            ),
            variant: "success",
            icon: "CheckIcon",
          });
      }).catch((error) => {
        console.log(error)
        makeToast({
          title: this.$t(
            "integrator.toast.confirm_install.success.title"
          ),
          text: this.$t(
            "integrator.toast.confirm_install.success.message"
          ),
          variant: "danger",
          icon: "CheckIcon",
        });
      })
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/base/bootstrap-extended/_variables.scss";

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}
</style>