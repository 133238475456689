<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="outline-primary">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input :placeholder="$t('common.terms.search_by_integration_name')" v-on:keyup.enter="search" v-model="searchTerm" />
          <b-input-group-append>
            <b-button variant="outline-primary" @click="search">{{ $t("common.terms.search") }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        v-for="(item, index) in items"
        :key="index"
        cols="4"
      >
        <Card 
          :service="item"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import Card from "@/layouts/components/Integrator/Card.vue";

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    Card
},
  data() {
    return {
      searchTerm: "",
      items: [],
    }
  },
  methods: {
    search() {
      this.$store.dispatch("setLoadingState", true);

      this.$store
        .dispatch("getWebServiceByName", { search: this.searchTerm })
        .then((response) => {
          this.items = response.items;
          this.$store.dispatch("setLoadingState", false);
        })
        .catch(() => {
          this.$store.dispatch("setLoadingState", false);
          makeToast({
            title: this.$t("integrator.toast.get_releases.error.title"),
            text: this.$t("integrator.toast.get_releases.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    }
  }
};
</script>

<style>
</style>